<template>
  <div>
    <addButton @click="showAddChoice()" :text="'Ajouter une Vidéo'"></addButton>

    <b-modal
      v-model="progressModal"
      id="modal-lg"
      size="m"
      title="Sélectionnez le fichier"
      title-class="font-18"
      hide-footer
    >
      <progress max="100" :value="progress">{{ progress }}%</progress>
    </b-modal>

    <b-modal
      v-model="modalFile"
      id="modal-lg"
      size="m"
      title="Sélectionnez le fichier"
      title-class="font-18"
      hide-footer
    >
      <div class="card">
        <div class="row mb-4">
          <label class="col-form-label col-lg-12">Fichier</label>
          <div class="col-lg-12">
            <DropZone @drop.prevent="drop" @change="selectedFile" />
            <span class="file-info">File: {{ dropzoneFile.name }}</span>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div></div>
        <div @click="showModalData"><span>suivant</span></div>
      </div>
    </b-modal>

    <b-modal
      v-model="modalData"
      id="modal-lg"
      size="m"
      title="Renseignez les métadonnées"
      title-class="font-18"
      hide-footer
    >
      <div class="card">
        <div class="row">
          <div class="col-lg-12">
            <label>Titre</label><br />
            <input
              id="projectbudget"
              name="projectbudget"
              type="text"
              class="form-control"
              placeholder="Entrez le titre"
              v-model="uploadVideo.title"
            />
          </div>

          <div class="col-lg-12">
            <label class="col-form-label">Programme</label>
            <div class="form-control">
              <select
                class="tags-input_text"
                v-model="uploadVideo.progammeId"
                :disabled="idProgram"
              >
                <option
                  v-for="program in programList"
                  :key="program.id"
                  :value="program.id"
                >
                  {{ program.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-12">
            <label class="col-form-label">Date de publication</label>
            <input
              id="projectbudget"
              name="projectbudget"
              type="date"
              class="form-control"
              placeholder="Entrez le titre"
              v-model="uploadVideo.releaseDate"
            />
          </div>
          <div class="col-lg-12">
            <label class="col-form-label">Catégories</label>
            <div class="form-control">
              <div
                v-for="(category, index) in categories"
                :key="index"
                class="tags-input_tag c2play-primary"
              >
                <span @click="removeCategory(index)"
                  ><i class="mdi mdi-close-thick"></i
                ></span>
                {{ category }}
              </div>
              <select
                @keydown="addCategory"
                @keydown.delete="removeLastCategory"
                class="tags-input_text"
                @change="addCategory"
                v-model="uploadVideo.categories"
              >
                <option
                  v-for="category in categoryList"
                  :key="category.id"
                  :value="category.name"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-12">
            <label class="col-form-label">Tags</label>
            <div class="form-control">
              <div
                v-for="(tag, index) in tags"
                :key="index"
                class="tags-input_tag c2play-primary"
              >
                <span @click="removeTags(index)"
                  ><i class="mdi mdi-close-thick"></i
                ></span>
                {{ tag }}
              </div>
              <select
                @keydown="addTags"
                @keydown.delete="removeLastTag"
                class="tags-input_text"
                @change="addTags"
                v-model="uploadVideo.tags"
              >
                <option v-for="tag in tagList" :key="tag.id" :value="tag.name">
                  {{ tag.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div @click="showAddModalFile"><span>retour</span></div>
        <div @click="showAddModalDescription"><span>suivant</span></div>
      </div>
    </b-modal>

    <b-modal
      v-model="modalDescription"
      id="modal-lg"
      size="m"
      title="Renseignez les métadonnées"
      title-class="font-18"
      hide-footer
    >
      <div class="card">
        <div class="row">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <label for="projectdesc" class="col-form-label"
              >Est-ce un extrait ?</label
            >
            <div>
              <b-form-checkbox
                v-model="isExcerpt"
                name="check-button"
                switch
                size="lg"
              >
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-lg-12">
            <label for="projectdesc" class="col-form-label"
              >Description courte</label
            >
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="uploadVideo.shortDescription"
              ></textarea>
            </div>
          </div>
          <div class="col-lg-12">
            <label for="projectdesc" class="col-form-label">Description</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="uploadVideo.description"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div @click="showModalData"><span>retour</span></div>
        <div>
          <addButton
            @click="uploadFile()"
            :text="'Ajouter une vidéo'"
          ></addButton>

          <!-- <addButton
            @click="addVideo()"
            :text="'Ajouter une vidéo'"
          ></addButton> -->
        </div>
      </div>
    </b-modal>
  </div>

  <b-modal
    v-model="addChoice"
    id="modal-xl"
    size="m"
    title="Que souhaitez vous faire ?"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <div class="row">
        <button
          type="submit"
          style="border-radius: 8px; width: 100%"
          class="btn c2play-primary"
          @click="showImportModalRef"
        >
          Importer depuis DailyMotion
        </button>
      </div>
      <div class="row mt-2">
        <button
          type="submit"
          style="border-radius: 8px; width: 100%"
          class="btn c2play-primary"
          @click="showAddModalFile()"
        >
          Sélectionner un fichier local
        </button>
      </div>
    </div>
  </b-modal>

  <b-modal
    v-model="importModalRef"
    id="modal-xl"
    size="xl"
    title="Importer une vidéo depuis Daily Motion"
    title-class="font-18"
    hide-footer
  >
    <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

    <div class="card-body">
      <b-alert v-model="isAuthError" variant="danger" class="mt-1" dismissible>
        {{ authError }}
      </b-alert>
      <form>
        <div class="form-group row mb-2">
          <div class="row">
            <div class="col-lg-6">
              <label>Référence</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="idDailyMotion"
                placeholder="Entrez la référence de la vidéo"
              />
            </div>

            <div class="col-lg-6">
              <label>Titre</label><br />
              <input
                id="projectbudget"
                name="projectbudget"
                type="text"
                class="form-control"
                placeholder="Entrez le titre"
                v-model="uploadVideo.title"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label class="col-form-label">Programme</label>
              <div class="form-control">
                <select
                  class="tags-input_text"
                  v-model="uploadVideo.progammeId"
                  :disabled="idProgram"
                >
                  <option
                    v-for="program in programList"
                    :key="program.id"
                    :value="program.id"
                  >
                    {{ program.title }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6">
              <label class="col-form-label">Date de publication</label>
              <input
                id="projectbudget"
                name="projectbudget"
                type="date"
                class="form-control"
                placeholder="Entrez le titre"
                v-model="uploadVideo.releaseDate"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label class="col-form-label">Catégories</label>
              <div class="form-control">
                <div
                  v-for="(category, index) in categories"
                  :key="index"
                  class="tags-input_tag c2play-primary"
                >
                  <span @click="removeCategory(index)"
                    ><i class="mdi mdi-close-thick"></i
                  ></span>
                  {{ category }}
                </div>
                <select
                  @keydown="addCategory"
                  @keydown.delete="removeLastCategory"
                  class="tags-input_text"
                  @change="addCategory"
                  v-model="uploadVideo.categories"
                >
                  <option
                    v-for="category in categoryList"
                    :key="category.id"
                    :value="category.name"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label">Tags</label>
              <div class="form-control">
                <div
                  v-for="(tag, index) in tags"
                  :key="index"
                  class="tags-input_tag c2play-primary"
                >
                  <span @click="removeTags(index)"
                    ><i class="mdi mdi-close-thick"></i
                  ></span>
                  {{ tag }}
                </div>
                <select
                  @keydown="addTags"
                  @keydown.delete="removeLastTag"
                  class="tags-input_text"
                  @change="addTags"
                  v-model="uploadVideo.tags"
                >
                  <option
                    v-for="tag in tagList"
                    :key="tag.id"
                    :value="tag.name"
                  >
                    {{ tag.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <label for="projectdesc" class="col-form-label"
                  >Est-ce un extrait ?</label
                >
                <div>
                  <b-form-checkbox
                    v-model="isExcerpt"
                    name="check-button"
                    switch
                    size="lg"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label for="projectdesc" class="col-form-label"
                >Description courte</label
              >
              <div class="col-lg-12">
                <textarea
                  id="projectdesc"
                  class="form-control"
                  rows="3"
                  v-model="uploadVideo.shortDescription"
                ></textarea>
              </div>
            </div>
            <div class="col-lg-6">
              <label for="projectdesc" class="col-form-label"
                >Description</label
              >
              {{ uploadVideo.description }}
              <div class="col-lg-12">
                <textarea
                  id="projectdesc"
                  class="form-control"
                  rows="3"
                  v-model="uploadVideo.description"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-lg-12" style="margin-top: 10px; text-align: center">
          <button
            type="submit"
            class="btn c2play-primary"
            @click="saveVideoFromDailyMotion()"
          >
            Importer
          </button>
        </div>
      </div>
    </div>
  </b-modal>

  <loaderProgress :visible="process" :progress="progress" :title="titre" />
</template>

<script>
import addButton from "./addButton.vue";
import axios from "axios";
import DropZone from "@/components/widgets/dropZone";
import { Api } from "../../helpers";
import { Erreur } from "../../helpers/error";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import loaderProgress from "./loaderProcess.vue";
import Swal from "sweetalert2";

export default {
  name: "addVideo",
  components: {
    DropZone,
    addButton,
    loaderProgress,
  },
  setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      console.log(dropzoneFile.value);
    };
    return { dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  props: {
    idProgram: {
      type: String,
    },
  },
  data() {
    return {
      process: false,
      isExcerpt: false,
      progress: 0,
      titre: "",
      modalDescription: false,
      modalFile: false,
      modalData: false,
      progressModal: false,
      url: "",
      upload_url: "",
      access_token: "",
      videoId: "",
      programList: [],
      categoryList: [],
      tagList: [],
      fileVideo: "",
      tags: [],
      categories: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
      sendedTag: [],
      sendedCategory: [],
      uploadVideo: {
        title: "",
        progammeId: "",
        releaseDate: "",
        tags: [],
        categories: [],
        shortDescription: "",
        description: "",
      },
      importModalRef: false,
      addChoice: false,
      idDailyMotion: "",
    };
  },
  methods: {
    addTags(event) {
      event.preventDefault();
      const val = event.target.value.trim();
      if (val.length > 0 && !this.tags.includes(val)) {
        this.tags.push(val);
        this.tagList.forEach((item) => {
          if (item.name == val) {
            this.sendedTag.push(item.id);
            console.log(this.sendedTag);
          }
        });
        event.target.value = "";
      }
    },

    removeTags(index) {
      this.tags.splice(index, 1);
      this.sendedTag.splice(index, 1);
      console.log(this.sendedTag);
    },

    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTags(this.tags.length - 1);
      }
    },

    addCategory(event) {
      event.preventDefault();
      const val = event.target.value.trim();
      if (val.length > 0 && !this.categories.includes(val)) {
        this.categories.push(val);
        this.categoryList.forEach((item) => {
          if (item.name == val) {
            this.sendedCategory.push(item.id);
          }
        });
        event.target.value = "";
      }
    },

    removeCategory(index) {
      this.categories.splice(index, 1);
      this.sendedCategory.splice(index, 1);
    },

    removeLastCategory(event) {
      if (event.target.value.length === 0) {
        this.removeTags(this.categories.length - 1);
      }
    },

    showAddChoice() {
      this.addChoice = true;
    },

    showImportModalRef() {
      this.importModalRef = true;
      this.addChoice = false;
    },

    showModalData() {
      this.modalData = true;
      this.modalDescription = false;
      this.modalFile = false;
    },

    showAddModalDescription() {
      this.modalDescription = true;
      this.modalData = false;
      this.modalFile = false;
    },

    showAddModalFile() {
      // this.addVideo();
      this.connectDailyMotion();
      this.modalFile = true;
      this.modalDescription = false;
      this.modalData = false;
      this.addChoice = false;
    },

    //Connexion au serveur DailyMotion
    connectDailyMotion() {
      Api.post("/streamvodv2/api/v2/video/get-token", {
        clientId: "17c354f652f487d2c9c9",
        clientSecret: "b27ba9fbd594738d8db425c47723b97eaefa4f2b",
        grantType: "password",
        password: "NjoNjo@2018",
        scope: "manage_videos",
        url: "https://api.dailymotion.com/oauth/token",
        username: "canal2motion@gmail.com",
      })
        .then((response) => {
          console.log(response.data.content);
          this.access_token = response.data.content;
          this.getUrlUploadFile();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //Récupération de l'url pour l'upload du fichier
    getUrlUploadFile() {
      axios
        .get("https://api.dailymotion.com/file/upload", {
          headers: {
            Authorization: `Bearer ${this.access_token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.upload_url = response.data.upload_url;
          // this.uploadFile();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // Uploder le fichier sur le serveur de DailyMotion
    uploadFile() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(this.progress);
      };
      this.process = true;

      this.modalDescription = false;
      this.process = true;
      this.titre = "Upload du fichier vidéo";

      const formData = new FormData();
      formData.append("file", this.dropzoneFile);

      axios
        .post(this.upload_url, formData, { onUploadProgress })
        .then((response) => {
          console.log(response.data);
          this.url = response.data.url;
          this.createVideo();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    // Créer la vidéo sur DailyMotion
    createVideo() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(this.progress);
      };
      this.process = true;

      this.modalDescription = false;
      this.process = true;
      this.titre = "Création de la video";

      const params = new URLSearchParams();
      params.append("url", this.url);

      axios
        .post(
          "https://api.dailymotion.com/user/x257yv3/videos",
          params,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${this.access_token}`,
            },
          },
          { onUploadProgress }
        )
        .then((response) => {
          console.log(response.data);
          this.videoId = response.data.id;
          this.PublishVideo();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    // Publier la vidéo sur DailyMotion
    PublishVideo() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(this.progress);
      };
      this.process = true;

      this.modalDescription = false;
      this.process = true;
      this.titre = "Publication de la vidéo";

      const params = new URLSearchParams();
      params.append("published", true);
      params.append("title", this.uploadVideo.title);
      params.append("is_created_for_kids", true);
      params.append("channel", "news");

      axios
        .post(
          `https://api.dailymotion.com/video/${this.videoId}`,
          params,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${this.access_token}`,
            },
          },
          { onUploadProgress }
        )
        .then((response) => {
          console.log(response.data);
          setTimeout(() => {
            this.addVideo();
          }, 20000);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    //Enregistrer les données de la vidéo dans la base de donnée de 2play
    addVideo() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(this.progress);
      };
      this.process = true;

      this.modalDescription = false;
      this.process = true;
      this.titre = "Sauvegarde de la vidéo";

      const requestData = {
        categoryIds: this.sendedCategory,
        tagIds: this.sendedTag,
        video: {
          description: this.uploadVideo.description,
          releaseDate: this.uploadVideo.releaseDate,
          shortDescription: this.uploadVideo.shortDescription,
          title: this.uploadVideo.title,
          isExtract: this.isExcerpt
        },
      };
      console.log(requestData);
      Api.post(
        `/streamvodv2/rest/v2/video/create?dailymotion_id=${this.videoId}&program_id=${this.uploadVideo.progammeId}&access_token=${this.access_token}`,
        requestData,
        { onUploadProgress }
      )
        .then((response) => {
          console.log(response.data);
          this.process = false;
          Swal.fire("Succes!", "Vidéo ajoutée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    //Enregistrer une vidéo de DailyMotion
    saveVideoFromDailyMotion() {
      this.importModalRef = false;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(this.progress);
      };
      this.process = true;

      this.modalDescription = false;
      this.process = true;
      this.titre = "Sauvegarde de la vidéo";

      const requestData = {
        categoryIds: this.sendedCategory,
        tagIds: this.sendedTag,
        video: {
          description: this.uploadVideo.description,
          releaseDate: this.uploadVideo.releaseDate,
          shortDescription: this.uploadVideo.shortDescription,
          title: this.uploadVideo.title,
          isExtract: this.isExcerpt,
        },
      };
      console.log(requestData);
      Api.post(
        `/streamvodv2/rest/v2/video/create-from-dailymotion?dailymotion_id=${this.idDailyMotion}&program_id=${this.uploadVideo.progammeId}`,
        requestData,
        { onUploadProgress }
      )
        .then((response) => {
          console.log(response.data);
          this.process = false;
          Swal.fire("Succes!", "Vidéo ajoutée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    //Récuperer une video de dailyMotion
    getVideoFromDailymotion() {
      this.importModalRef = false;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(this.progress);
      };
      this.process = true;

      this.modalDescription = false;
      this.process = true;

      this.titre = "Sauvegarde de la vidéo";

      Api.post(
        `/streamvodv2/rest/v2/video/save-from-dailymotion?id_dailymotion=${this.idDailyMotion}&program_id=${this.uploadVideo.progammeId}`,
        { onUploadProgress }
      )
        .then((response) => {
          console.log(response.data);
          this.process = false;
          Swal.fire("Succes!", "Vidéo ajoutée", "success");
          //location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.uploadVideo.progammeId = this.idProgram;
    console.log(this.uploadVideo.progammeId);
    console.log(this.idProgram);
    Api.get("/streamvodv2/api/v2/program/all")
      .then((res) => {
        this.programList = res.data.content;
        console.log(res);
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvodv2/api/v2/category/all")
      .then((res) => {
        this.categoryList = res.data.content;
        console.log(this.categoryList);
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvodv2/api/v2/tag/all")
      .then((res) => {
        this.tagList = res.data.content;
        console.log(this.tagList);
      })
      .catch((error) => {
        this.process = false;
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<style lang="scss" scoped></style>
